export const delay = ({ milliseconds }) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const delayWithAbort = ({ milliseconds, signal }) => {
  return   new Promise((resolve, reject) => {
    const timer = setTimeout(resolve, milliseconds);
    signal?.addEventListener('abort', () => {
      clearTimeout(timer);
      reject(new Error('Delay aborted'));
    });
  })
}


export const loopingDelay = async ({
  MAX_NUM_OF_RETRIES,
  attemptsLimitExceededReturn,
  delayDuration = 1000,
}) => {
  let retryIndex = 0
  while (!window._smartico) {
    if (retryIndex >= MAX_NUM_OF_RETRIES) {
      return attemptsLimitExceededReturn ?? null
    }
    retryIndex++
    await delay({ milliseconds: delayDuration })
  }
}

export const waitForCondition = async ({
  conditionFn,
  MAX_NUM_OF_RETRIES,
  attemptsLimitExceededReturn,
  delayDuration = 1000,
  signal
}) => {
  let retryIndex = 0;
  while (!(await conditionFn())) {
    if (signal?.aborted) {
      throw new Error('waitForCondition aborted');
    }
    
    if (retryIndex >= MAX_NUM_OF_RETRIES) {
      return attemptsLimitExceededReturn ?? null;
    }
    retryIndex++;
    await delayWithAbort({ milliseconds: delayDuration, signal });
  }
};
