import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'
import { MODAL_HEADER_HEIGHT } from '../../atoms/modalHeader/styles'

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  max-height:  calc(100vh - ${MODAL_HEADER_HEIGHT});
  align-items: center;
  flex-direction: column;
  gap: 2.375rem;
  padding: 1rem;

  ${themeMedia.desktop`
    padding: 0;
    justify-content: center;
    max-height: 100%;
  `};
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: #000 !important;
    text-decoration: underline;
  }

  small {
    padding: 0.5rem 0;
    text-align: center;
    display: block;
  }

  ${themeMedia.desktop`
    margin: 0 auto;
    padding: 0;
  `};
`

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;

  > * {
    text-align: center;
  }

  h2 {
    font-size: 22px;
    text-align: center;
  }
`

export const StyledSelectBankWrapper = styled.div`
  width: 100%;
  max-width: 594px;
  padding-bottom: 6px;

  ${themeMedia.desktop`
    padding-bottom: 0;
  `};
`

export const StyledButtonWrapper = styled.div`
  width: 100%;
  margin-top: auto;

  button {
    width: 100%;
    max-width: '500px',
  }

  ${themeMedia.desktop`
    margin-top: 2rem;
  `};
`