import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const ImageWrapper = styled.div`
  position: relative;

  .main-img {
    width: 100px;
    height: 100px;

    ${themeMedia.desktop`
      width: 100px;
      height: 100px;
    `}
  }

  .little-icon {
    position: absolute;
    right: -5px;
    width: 30px;
    height: 30px;

    ${themeMedia.desktop`
      width: 30px;
      height: 30px;
    `}
  }
`

export const Message = styled.div`
  font-size: 1rem;
  text-align: center;

  line-height: ${({ isBankAccountProcess }) =>
    isBankAccountProcess ? '1.4' : null};

  padding: ${({ isBankAccountProcess }) =>
    isBankAccountProcess ? '30px 0 1rem 0' : '1rem 0'};
`

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  padding: ${({ isBankAccountProcess }) =>
    isBankAccountProcess ? '1rem 0 30px 0' : '4rem 0 2rem'};
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;

  margin-top: 52px;

  ${themeMedia.desktop`
    margin-top: 0;
    height: auto;
    max-height: auto;
  `};

  button {
    margin-top: auto;

    ${themeMedia.desktop`
      margin-top: 2rem;
    `};
  }
`