import React, { useContext, useEffect, useState } from 'react'

import { Button } from '../../../atomic-components/atoms/button'
import { Modal } from '../../../atomic-components/atoms/modal'

import { Content, StyledButtonWrapper, StyledSelectBankWrapper, Wrapper } from './styles'
import { useCreateBankAccountContext } from '../../../context/createBankAccountProvider'

import { BankDetailsForm } from '../../molecules/bankDetailsForm'
import { AuthContext } from '../../../context/authProvider'
import { checkFormFields, getBrazilianTopBanks } from './utils'
import { CAFSteps } from '../CAFSteps'
import {
  createBankAccountV2,
  deleteBankAccount,
  getLivenessBankAccount,
  requestLivenessProcess,
} from '../../../adapters/payments'
import { If } from '../../atoms/if'
import { usePaymentDrawerContext } from '../../../context/paymentDrawerProvider'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { cookiePrefix } from '../../../utils/cookies'
import { ImageWrapper, Message, StyledContent, Title } from '../CAFSteps/styles'
import { Image } from '../../atoms/image'

import lockIcon from '../../../images/icon_lock_gray.svg'
import { getBankAccountFormFields } from '../../molecules/bankAccounts/formFields'
import { toast } from '../../atoms'
import { SearchableSelect } from '../SearchableSelect'
import { getBrazilianBanks } from '../../../adapters/common'
import { AddBankAccountHeader } from '../../molecules/AddBankAccountHeader'
import { useEventTracking } from '../../../context/eventTrackingProvider'
import { themeSizes } from '../../../styles/theme'

import SuccessWizard from '../../molecules/AidResultWizard/SuccessWizard'
import ProblemWizard from '../../molecules/AidResultWizard/ProblemWizard'
import { useAidCustomer } from '../../../hooks/useAidCustomer'
import { getAidCustomerSteps } from '../AidCustomerWizard/utils'
import AidCustomerWizard from '../AidCustomerWizard'

const stepTypes = {
  BANK_SELECT: 0,
  BANK_DETAILS: 1,
  VERIFICATION: 2,
  DEPOSIT: 3,
  CLOSE_CONFIRMATION: 4,
}

const AddBankAccountModal = ({ hideOverlay, translate }) => {
  const { isOpen, closeCreateBankAccount } = useCreateBankAccountContext()
  const { isLoggedIn, user } = useContext(AuthContext)
  const { open: openDepositDrawer } = usePaymentDrawerContext()
  const { getSessionValue, removeSessionValue } = useSessionStorage()

  const [creationStep, setCreationStep] = useState(0)
  const [enableNextButton, setEnableNextButton] = useState(false)
  const [bankDetails, setBankDetails] = useState({
    accountType: 'CRN',
  })
  const [livenessData, setLivenessData] = useState(null)
  const [isWaitingVerification, setIsWaitingVerification] = useState(false)
  const [verificationStarted, setVerificationStarted] = useState(false)
  const [isRejected, setIsRejected] = useState(false)
  const [confirmingClose, setConfirmingClose] = useState(false)
  const [waitingLivenessAccount, setWaitingLivenessAccount] = useState(null)
  const [formDataFields, setFormDataFields] = useState([])

  const [brazilianBankOptions, setBrazilianBankOptions] = useState([])
  const [selectedBank, setSelectedBank] = useState(null)
  const [actualStep, setActualStep] = useState(null)
  const { trackEvent, EVENT_NAMES, EVENT_LOCATIONS } = useEventTracking()

  const isMobile = window.innerWidth < themeSizes.desktop

  const { aidWizards, toggleWizard, handleAttemptsFailedCount } =
    useAidCustomer()

  const aidCustomerSteps = getAidCustomerSteps(translate)

  const handleModalClose = () => {
    if (creationStep === stepTypes.BANK_DETAILS) {
      setFormDataFields([])
    }

    if (creationStep !== stepTypes.VERIFICATION) {
      setActualStep(creationStep)
      setCreationStep(stepTypes.CLOSE_CONFIRMATION)
      return
    }

    setConfirmingClose(true)
  }

  const handleNextStep = async () => {
    if (creationStep + 1 === stepTypes.VERIFICATION) {
      if (typeof window !== 'undefined') {
        window.dataLayer.push({
          event: 'ba_liveness_page_viewed',
        })
      }

      if (livenessData) {
        setCreationStep(creationStep + 1)
        return
      }

      getLivenessData()
    }

    setCreationStep(creationStep + 1)
  }

  const handlePreviousStep = () => {
    if (
      creationStep === stepTypes.BANK_DETAILS ||
      creationStep === stepTypes.VERIFICATION
    ) {
      setFormDataFields([])
    }

    setCreationStep(creationStep - 1)
  }

  const getLivenessData = async () => {
    const {
      data: { link, onboardingId },
    } = await requestLivenessProcess()

    setLivenessData({
      url: link,
      onboardingId,
    })
  }

  const getBankOptions = async () => {
    const brazilianBanks = (await getBrazilianBanks()).data.data.sort((a, b) =>
      a.name.localeCompare(b.name)
    )

    brazilianBanks.forEach((bank) => {
      const name = bank.name.split(' - ')[1]
      const code = bank.name.split(' - ')[0]
      const fullName = bank.name

      bank.fullName = fullName
      bank.name = name
      bank.code = code
    })

    setBrazilianBankOptions(brazilianBanks)

    return brazilianBanks
  }

  const getInitialData = async () => {
    await getBankOptions()
  }

  const handleBankAccountCreation = async () => {
    if (waitingLivenessAccount) {
      removeSessionValue(`${cookiePrefix}pendingLivenessAccount`)

      toast.success(translate('account.deposit.bankAccountCreated'))
      window.postMessage('bankAccountCreated', '*')
      closeCreateBankAccount()
      openDepositDrawer()

      return
    }

    const model = {
      ...bankDetails,
      onboardingId: livenessData.onboardingId,
    }

    const { ok } = await createBankAccountV2(model)

    if (!ok) {
      return
    }

    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'ba_registration_successful',
      })
    }

    toast.success(translate('account.deposit.bankAccountCreated'))
    window.postMessage('bankAccountCreated', '*')
    closeCreateBankAccount()
    openDepositDrawer()
  }

  const handleCheckWaitingLivenessAccount = () => {
    const pendingbankAccount = getSessionValue(
      `${cookiePrefix}pendingLivenessAccount`
    )

    if (pendingbankAccount) {
      setWaitingLivenessAccount(pendingbankAccount)
    }
  }

  const handleSavePreviousAccount = async () => {
    const {
      data: { url, onboardingId },
    } = await getLivenessBankAccount(waitingLivenessAccount.id)

    setLivenessData({
      url,
      onboardingId,
    })

    setCreationStep(stepTypes.VERIFICATION)
  }

  const handleDeletePreviousAccount = async () => {
    const response = await deleteBankAccount(waitingLivenessAccount.id)

    if (!response.ok) {
      return toast.error(response.error.message)
    }

    removeSessionValue(`${cookiePrefix}pendingLivenessAccount`)
    toast.success(translate('addBankAccount.formDeleteAccountMessage'))
    window.postMessage('bankAccountCreated', '*')
    closeCreateBankAccount()
  }

  const handleSelectBank = (bank) => {
    setSelectedBank(bank)
    setBankDetails({ ...bankDetails, bankId: bank.id })
    setCreationStep(stepTypes.BANK_DETAILS)
  }

  useEffect(() => {
    if (!isLoggedIn) return

    getInitialData()
    handleCheckWaitingLivenessAccount()
  }, [translate, isLoggedIn])

  useEffect(() => {
    if (creationStep !== stepTypes.BANK_DETAILS) {
      return
    }

    const enableButton = checkFormFields(bankDetails)
    setEnableNextButton(enableButton)
  }, [bankDetails])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'ba_creation_page_viewed',
      })
    }
  }, [])

  useEffect(() => {
    if (creationStep !== stepTypes.BANK_DETAILS) {
      return
    }

    getBankAccountFormFields(translate).then((fields) => {
      setFormDataFields(fields)
    })
  }, [creationStep])

  useEffect(() => {
    const { SCREEN_LOADED } = EVENT_NAMES
    const {
      ADD_BANK_ACCOUNT_START,
      ADD_BANK_ACCOUNT_DETAILS,
      ADD_BANK_VERIFY_ACCOUNT,
    } = EVENT_LOCATIONS
    const { BANK_SELECT, BANK_DETAILS, VERIFICATION } = stepTypes

    if (creationStep === BANK_SELECT)
      return trackEvent(SCREEN_LOADED, {
        screen_name: ADD_BANK_ACCOUNT_START,
      })

    if (creationStep === BANK_DETAILS)
      return trackEvent(SCREEN_LOADED, {
        screen_name: ADD_BANK_ACCOUNT_DETAILS,
      })

    if (creationStep === VERIFICATION)
      return trackEvent(SCREEN_LOADED, {
        screen_name: ADD_BANK_VERIFY_ACCOUNT,
      })
  }, [creationStep])

  const createCloseConfirmationContent = () => (
    <StyledContent>
      <Title isBankAccountProcess>
        {translate('addBankAccount.CloseVerificationTitle')}
      </Title>

      <ImageWrapper>
        <Image url={lockIcon} className="main-img" />
      </ImageWrapper>

      <Message isBankAccountProcess>{translate('addBankAccount.CloseVerificationText')}</Message>
      <Button
        expand
        onClick={() => {
          setCreationStep(actualStep)
        }}
      >
        {translate('addBankAccount.CloseVerificationReturn')}
      </Button>
      <Button
        expand
        bordered
        dark
        style={{ 'margin-top': '1rem' }}
        onClick={closeCreateBankAccount}
      >
        {translate('addBankAccount.CloseVerificationClose')}
      </Button>
    </ StyledContent>
  )

  const getStepContent = {
    0: () => (
      <StyledSelectBankWrapper>
        <AddBankAccountHeader
          pageTitle={translate('addBankAccount.SelectBankTitle')}
          pageSubtitle={translate('addBankAccount.SelectBankSubTitle')}
        />

        <SearchableSelect
          options={brazilianBankOptions}
          initialList={getBrazilianTopBanks(brazilianBankOptions)}
          onSelect={(bank) => handleSelectBank(bank)}
          translate={translate}
        />
      </StyledSelectBankWrapper>
    ),
    1: () => (
      <BankDetailsForm
        formFields={formDataFields}
        setBankDetails={setBankDetails}
        bankDetails={bankDetails}
        translate={translate}
        waitingLivenessAccount={waitingLivenessAccount}
        user={user}
        selectedBank={selectedBank}
        handlePreviousStep={handlePreviousStep}
      />
    ),
    2: () => {
      if (!livenessData) return <></>

      return (
        <CAFSteps
          cafIframeUrl={livenessData.url}
          confirmBeforeClosing={true}
          isWaitingVerification={isWaitingVerification}
          setIsWaitingVerification={setIsWaitingVerification}
          idVerificationStarted={verificationStarted}
          setIdVerificationStarted={setVerificationStarted}
          showRejected={isRejected}
          onStartVerification={() => setIsRejected(false)}
          onClose={closeCreateBankAccount}
          closeCafIFrame={handleBankAccountCreation}
          shouldUpdateUser={false}
          isBankAccountProcess
          confirmingClose={confirmingClose}
          setConfirmingClose={setConfirmingClose}
          waitingAltDescription={translate(
            'addBankAccount.verificationAwaitDescription'
          )}
          startAltDescription={translate(
            'addBankAccount.verificationStartDescription'
          )}
          closeAltDescription={translate(
            'addBankAccount.CloseVerificationText'
          )}
          closeAltButtonText={translate(
            'addBankAccount.CloseVerificationReturn'
          )}
          onAttemptsFailed={handleAttemptsFailedCount}
        />
      )
    },
    3: null,
    4: createCloseConfirmationContent,
  }

  const toggleContactUs = () => {
    if (!window?.Intercom) return
    if (window.isIntercomOpen) return

    window.Intercom('show')
  }

  return (
    <Modal
      theme="bank-account"
      isOpen={isOpen}
      onClose={handleModalClose}
      hideClose={
        creationStep === stepTypes.CLOSE_CONFIRMATION ||
        confirmingClose === true
      }
      hideOverlay={hideOverlay ? hideOverlay : null}
      isMobile={isMobile ? isMobile : null}
      title={translate('addBankAccount.title')}
      onBack={handlePreviousStep}
      hideBackButton={
        creationStep === stepTypes.BANK_SELECT ||
        creationStep === stepTypes.CLOSE_CONFIRMATION
      }
      stickHeaderToTop={!!isMobile}
    >
      <Wrapper>
        <Content>
          <If
            condition={aidWizards.aidCustomer}
            render={() => (
              <AidCustomerWizard
                steps={aidCustomerSteps}
                onLastClick={() => toggleWizard('aidSuccess', true)}
                handleClose={() => {
                  toggleWizard('aidCustomer', false)
                  toggleWizard('aidSuccess', true)
                }}
              />
            )}
          />

          <If
            condition={aidWizards.aidSuccess}
            render={() => (
              <SuccessWizard
                onClose={() => toggleWizard('aidSuccess', false)}
                title={translate('aidCustomer.success.title')}
                description={translate('aidCustomer.success.description')}
              />
            )}
          />

          <If
            condition={aidWizards.aidProblem}
            render={() => (
              <ProblemWizard
                onClose={() => toggleWizard('aidProblem', false)}
                title={translate('aidCustomer.problem.title')}
                description={translate('aidCustomer.problem.description')}
                buttonText={translate('aidCustomer.problem.buttonText')}
                onClick={() => {
                  toggleContactUs()
                  toggleWizard('aidProblem', false)
                }}
              />
            )}
          />

          {getStepContent[creationStep]()}

          <If
            condition={
              creationStep === stepTypes.BANK_DETAILS && !waitingLivenessAccount
            }
            render={() => (
              <StyledButtonWrapper>
                <Button
                  expand
                  disabled={!enableNextButton}
                  onClick={handleNextStep}
                >
                  {translate('addBankAccount.nextButton')}
                </Button>
              </StyledButtonWrapper>
            )}
          />

          <If
            condition={
              creationStep === stepTypes.BANK_DETAILS && waitingLivenessAccount
            }
            render={() => (
              <>
                <Button
                  expand
                  style={{
                    'margin-top': '2rem',
                    'max-width': '500px',
                  }}
                  onClick={handleSavePreviousAccount}
                >
                  {translate('addBankAccount.formSaveAccountLiveness')}
                </Button>

                <Button
                  expand
                  outlineColor="black"
                  textColor="black"
                  style={{
                    'margin-top': '1rem',
                    'max-width': '500px',
                  }}
                  onClick={handleDeletePreviousAccount}
                >
                  {translate('addBankAccount.formForgetAccountLiveness')}
                </Button>
              </>
            )}
          />
        </Content>
      </Wrapper>
    </Modal>
  )
}

export default AddBankAccountModal
