import BCO_DO_BRASIL_S_A from '../images/banks/banco_do_brasil.svg'
import BANCO_INTER from '../images/banks/banco_inter.svg'
import BANCO_PAN from '../images/banks/banco_pan.svg'
import BANCO_BANRISUL from '../images/banks/banco_banrisul.svg'
import BANCO_BRADESCO from '../images/banks/banco_bradesco.svg'
import BANCO_C6 from '../images/banks/banco_c6.svg'
import BANCO_CAIXA from '../images/banks/banco_caixa.svg'
import BANCO_ITAU from '../images/banks/banco_itau.svg'
import BANCO_MERCADO_PAGO from '../images/banks/banco_mercado_pago.svg'
import BANCO_NEON from '../images/banks/banco_neon.svg'
import BANCO_NU from '../images/banks/banco_nu.svg'
import BANCO_PAGSEGURO from '../images/banks/banco_pagseguro.svg'
import BANCO_PICPAY from '../images/banks/banco_picpay.svg'
import BANCO_SANTANDER from '../images/banks/banco_santander.svg'
import DEFAULT_BANK from '../images/banks/icon_bankaccount.svg'

export const bankIcons = {
  '00000000': BCO_DO_BRASIL_S_A,
  '00416968': BANCO_INTER,
  '59285411': BANCO_PAN,
  '92702067': BANCO_BANRISUL,
  '60746948': BANCO_BRADESCO,
  '31872495': BANCO_C6,
  '00360305': BANCO_CAIXA,
  '60701190': BANCO_ITAU,
  '17192451': BANCO_ITAU,
  '10573521': BANCO_MERCADO_PAGO,
  '20855875': BANCO_NEON,
  '18236120': BANCO_NU,
  '08561701': BANCO_PAGSEGURO,
  '22896431': BANCO_PICPAY,
  '90400888': BANCO_SANTANDER,
}

export const getBankIcon = (bankName) => {
  return bankIcons[bankName] || DEFAULT_BANK
}
